export enum Aspects {
    withHat = 'With hat',
    bald = 'Bald',
    withMoustach = 'With moustach',
    withSword = 'With sword',
    withFireWeapon = 'With fire weapon',
    furry = 'Furry',
    withMount = 'With mount',
    chonky = 'Chonky',
    humanoidWomen = 'Humanoid women',
    cantTalk = 'Can\'t talk'
}