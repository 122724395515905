const sound = require('./../assets/audio/default.mp3');
const volume = 0.1;
let audio: any;
let hasToCreateAudio = true;

export const useAudio = (): { audio: Howl; ready: boolean; } => {
  const createAudioContext = async () => {
    const { Howl } = await import('howler');
    audio = new Howl({ src: sound, volume });
  }

  if (!audio && hasToCreateAudio) {
    hasToCreateAudio = false;
    createAudioContext();
  }

  return { audio, ready: !!audio };
};

export const stopAudio = (): void => {
  const fadeOutTime = 2000;

  const soundIds: any[] = audio._getSoundIds();
  soundIds.forEach(soundId => {
    audio.fade(volume, 0, fadeOutTime, soundId)
    setTimeout(() => audio.stop(soundId), fadeOutTime);
  });
};

export const playAudio = (): void => {
  audio?.stop();
  audio?.play();
};