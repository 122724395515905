import { SessionStorageData, SessionStorageDataEvents } from '../enums/StorageData';
import { getStorageValue, setStorageValue } from './StorageService';
import { Language, Translations, languages } from '../databases/languages';
import i18next from 'i18next';

export const setSelectedLanguage = (language: string): void => {
  setStorageValue(SessionStorageData.language, language);
  window.dispatchEvent(new Event(SessionStorageDataEvents.language));

  if (i18next.isInitialized) {
    i18next.changeLanguage(language);
  }
};

export const getSelectedLanguage = (): string => {
  const storedLanguage = getStorageValue(SessionStorageData.language, undefined);
  if (storedLanguage) {
    return storedLanguage;
  }

  const defaultLanguage = getDefaultLanguage();
  setSelectedLanguage(defaultLanguage);

  return defaultLanguage;
};

export const getLanguages = (): Language[] => {
  return languages;
};

export const getLanguagesWithFiles = (): { [lang: string]: { common: Translations } } => {
  return languages.reduce((acc, lang) => ({ ...acc, [lang.name]: { common: lang.translations } }), {});
};

export const getDefaultLanguage = (): string => {
  const availableLanguages = languages.map(lang => lang.name);
  const navigatorLanguage = navigator.language.split('-')[0];

  return availableLanguages.includes(navigatorLanguage) ? navigatorLanguage : availableLanguages[0];
};