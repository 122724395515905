import common_en from "./../translations/en/common.json";
import image_en from "./../assets/img/flags/en.png";
import common_es from "./../translations/es/common.json";
import image_es from "./../assets/img/flags/es.png";
import common_fr from "./../translations/fr/common.json";
import image_fr from "./../assets/img/flags/fr.png";

export interface Language {
    name: string;
    translations: Translations;
    imageUrl: string;
};

export interface Translations {
    [key: string]: string;
};

export const languages: Language[] = [
    { name: 'en', translations: common_en, imageUrl: image_en },
    { name: 'es', translations: common_es, imageUrl: image_es },
    { name: 'fr', translations: common_fr, imageUrl: image_fr }
];