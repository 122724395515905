import { LocalStorageData, SessionStorageData } from '../enums/StorageData';

export const setStorageValue = (key: LocalStorageData | SessionStorageData, value: any): void => {
  const storageObj = (Object.values(LocalStorageData).includes(key as LocalStorageData)) ? localStorage : sessionStorage;
  storageObj.setItem(key, (value !== undefined) ? JSON.stringify(value) : '');
};

export const getStorageValue = (key: LocalStorageData | SessionStorageData, defaultValue: any): any => {
  const storageObj = (Object.values(LocalStorageData).includes(key as LocalStorageData)) ? localStorage : sessionStorage;
  const saved = storageObj.getItem(key);
  return (saved) ? JSON.parse(saved) : defaultValue;
};

export const removeStorageValue = (key: LocalStorageData | SessionStorageData): any => {
  const storageObj = (Object.values(LocalStorageData).includes(key as LocalStorageData)) ? localStorage : sessionStorage;
  storageObj.removeItem(key);
};

export const listenToStorageChanges = (event: string, callback: any): any => {
  const onSessionStorageChange = (): void => callback();

  window.addEventListener(event, onSessionStorageChange);
  return () => window.removeEventListener(event, onSessionStorageChange);
};