import './RandomInitial.css';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { champions } from '../../../databases/champions';
import { getSummaryContent, setSummaryContent } from '../../../services/SummaryService';
import { useTranslation } from 'react-i18next';

interface InitialsWithChampions {
  initial: string;
  champions: string[];
}

function RandomInitial() {
  const { t } = useTranslation('common');

  const [showingRandomInitial, setShowingRandomInitial] = useState<boolean>(false);
  const [randomInitial, setRandomInitial] = useState<string>();

  const getValidInitialsAndChampions = (): InitialsWithChampions[] => {
    return champions
      .reduce((acc, champion) => {
        const initial = champion.name[0];
        const existingItem = acc.find(item => item.initial === initial);
        if (existingItem) {
          existingItem.champions.push(champion.name);
          return acc;
        }

        return [...acc, { initial, champions: [champion.name] }];
      }, [] as InitialsWithChampions[])
      .filter(item => item.champions.length >= 5);
  };

  const getRandomLetter = (): void => {
    setRandomInitial(undefined);
    setTimeout(() => {
      const randomInitial = initialsWithChampions[Math.floor(Math.random() * Object.keys(initialsWithChampions).length)].initial;
      setRandomInitial(randomInitial);
      setShowingRandomInitial(true);
      updateSummaryContentOptions(randomInitial);
    }, 500);
  };

  const updateSummaryContentOptions = (initial: string): void => {
    const summaryContent = getSummaryContent();
    setSummaryContent({ ...summaryContent, options: initialsWithChampions.find(item => item.initial === initial)?.champions });
  };

  const initialsWithChampions = getValidInitialsAndChampions();

  return (
    <div className='random-initial-component'>
      <div className={`random-initial small-font-size${randomInitial ? ' show' : ''}`}>{randomInitial}</div>
      <Button className='custom-button' variant='secondary' onClick={getRandomLetter}>{showingRandomInitial ? t('change_initial_letter') : t('show_initial_letter')}</Button>
    </div>
  );
}

export default RandomInitial;
