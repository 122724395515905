export enum Regions {
    bandleCity = 'Bandle city',
    bilgewater = 'Bilgewater',
    demacia = 'Demacia',
    ionia = 'Ionia',
    ixtal = 'Ixtal',
    noxus = 'Noxus',
    piltover = 'Piltover',
    shadowIsles = 'Shadow isles',
    shurima = 'Shurima',
    targon = 'Targon',
    freljord = 'Freljord',
    void = 'Void',
    zaun = 'Zaun',
    runaterra = 'Runaterra'
}