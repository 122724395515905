import './PickEnemyTeamChampions.css';
import { Form } from 'react-bootstrap';
import { champions } from '../../../databases/champions';
import { getTeams } from '../../../services/TeamsService';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function PickEnemyTeamChampions() {
  const { t } = useTranslation('common');

  const teams = getTeams();
  const [values, setValues] = useState<string[][]>([]);

  const updateValues = (teamIndex: number, playerIndex: number, value: string): void => {
    const _values = [...values];
    _values[teamIndex][playerIndex] = value;
    setValues(_values);
  };

  const initValues = (): void => {
    const _values = teams.reduce((acc, team) => ([...acc, new Array(team.length).fill('')]), [] as string[][]);
    setValues(_values);
  };

  if (!values.length) {
    initValues();
  }

  return (
    <div className='pick-enemy-team-champions-component'>
      <Form className='form'>
        {teams.map((team, tIndex) => (
          <Form.Group className='team-container' key={`team-${tIndex}`}>
            <div className='team-title small-font-size'>{t('team_decides_enemy_champions', { firstTeam: ((tIndex - 1) < 0) ? teams.length : tIndex, secondTeam: tIndex + 1 })}</div>
            <div className='champion-selectors'>
              {team.map((player, pIndex) => (
                <Form.Select key={`team-${pIndex}`} className={`team-input${(!!values[tIndex]?.[pIndex]) ? '' : ' empty-select'}`} value={values[tIndex]?.[pIndex]} size='sm' onChange={(e) => updateValues(tIndex, pIndex, e.target.value)}>
                  <option value={''} hidden>{t('champion')}</option>
                  {champions.map((champion, cIndex) => (<option key={`champion-${cIndex}`}>{champion.name}</option>))}
                </Form.Select>
              ))}
            </div>
          </Form.Group>
        ))}
      </Form>
    </div>
  );
}

export default PickEnemyTeamChampions;
