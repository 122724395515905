import { SessionStorageData } from "../enums/StorageData";
import { getStorageValue, setStorageValue } from "./StorageService";

interface TeamsConfig {
  players: Player[];
  teams: Player[][];
}

export interface Player {
  name: string;
  main: string;
}

export const setTeams = (players: Player[], teamsNumber: number): void => {
  const shuffledPlayers = [...players].sort((a, b) => 0.5 - Math.random());

  const playersPerTeam = players.length / teamsNumber;
  let lastIndex = 0;

  const teams = new Array(teamsNumber).fill(0).reduce((acc, _) => {
    const lastPlayerIndex = playersPerTeam + lastIndex;
    const teamPlayers = shuffledPlayers.slice(lastIndex, lastPlayerIndex);
    lastIndex = lastPlayerIndex;
    return [...acc, teamPlayers];
  }, []);

  setStorageValue(SessionStorageData.teamsConfig, { players, teams });
};

export const getTeams = (): Player[][] => {
  return (getStorageValue(SessionStorageData.teamsConfig, { teams: [] }) as TeamsConfig).teams;
};

export const resetTeams = (): void => {
  const config = getStorageValue(SessionStorageData.teamsConfig, { teams: [], players: [] } as TeamsConfig);
  setStorageValue(SessionStorageData.teamsConfig, { ...config, teams: [] });
};

export const getTeamsConfig = (): TeamsConfig => {
  return getStorageValue(SessionStorageData.teamsConfig, { players: [], teams: [] });
};