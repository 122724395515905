import './ConfigBlock.css';
import { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import RouletteConfig from './roulette-config/RouletteConfig';
import LanguageConfig from './language-config/LanguageConfig';

function ConfigBlock() {
  const { t } = useTranslation('common');

  const [show, setShow] = useState<boolean>(false);

  return (
    <div className='config-block-component'>
      <div className='config-button clickable-item' onClick={() => setShow(true)}></div>
      <Modal id='config-modal' show={show} centered onHide={() => setShow(false)} backdrop={true}>
        <Modal.Body>
          <Tabs defaultActiveKey='roulette'>
            <Tab eventKey='roulette' title={t('roulette')}>
              <RouletteConfig />
            </Tab>
            <Tab eventKey='language' title={t('language')}>
              <LanguageConfig />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ConfigBlock;
