import './RouletteConfig.css';
import { useState } from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { WheelOption, WheelSubOption } from '../../../databases/wheelOptions';
import { getSessionWheelOptions, setSessionWheelOptions } from '../../../services/WheelOptionsService';

function RouletteConfig() {
  const { t } = useTranslation('common');

  const [parsedWheelOptions, setParsedWheelOptions] = useState<WheelOption[]>();
  const [openedAccordionIndex, setOpenedAccordionIndex] = useState<string>('');

  const updateDefaultHide = (event: any, wheelOption: (WheelOption | WheelSubOption), mainWheelOption?: WheelOption): void => {
    event.stopPropagation();

    const defaultHideValue = !event.target.checked;

    wheelOption.defaultHide = defaultHideValue;

    if (!!mainWheelOption) {
      // Check all enabled or disabled
      if (!!mainWheelOption.subOptions?.find(subOption => !subOption.defaultHide)) {
        mainWheelOption.defaultHide = false;
      } else if (mainWheelOption.subOptions?.every(subOption => subOption.defaultHide)) {
        mainWheelOption.defaultHide = true;
      }
    } else if ((wheelOption as WheelOption).subOptions?.length) {
      (wheelOption as WheelOption).subOptions?.map(subOption => subOption.defaultHide = defaultHideValue);
    }

    setParsedWheelOptions([...parsedWheelOptions as WheelOption[]]);
  };

  const submitForm = (): void => {
    setSessionWheelOptions(parsedWheelOptions as WheelOption[]);
  };

  const updateOpenedAccordion = (event: any, accordionItemIndex: number): void => {
    // If header, header title or checkbox header has been clicked
    if (event.target.name?.indexOf('header-checkbox') >= 0 || event.target.type === 'button' || event.target.className.indexOf('header-title') >= 0) {
      const currentAccordionItemIndex = accordionItemIndex.toString();
      const accordionIndexToOpen = (event.target.tagName === 'INPUT')
        ? (event.target.checked) ? currentAccordionItemIndex : ''
        : (currentAccordionItemIndex === openedAccordionIndex) ? '' : currentAccordionItemIndex

      setOpenedAccordionIndex(accordionIndexToOpen);
    }
  };

  const resetWheelConfiguration = (): void => {
    setParsedWheelOptions(getSessionWheelOptions());
  };

  if (!parsedWheelOptions) {
    resetWheelConfiguration();
  }

  return (
    <div className='roulette-config-component'>
      <div className='medium-font-size'>{t('roulette_config_section')}</div>
      <Form className='roulette-options-form' onSubmit={submitForm}>
        <Accordion activeKey={openedAccordionIndex}>
          {parsedWheelOptions?.map((wheelOption, oIndex) => (
            <Accordion.Item eventKey={`${oIndex}`} key={`wheel-option-${oIndex}`} onClick={(e) => updateOpenedAccordion(e, oIndex)}>
              <Accordion.Header className={`${wheelOption.subOptions?.length ? '' : 'accordion-header-without-content'}`}>
                <div className='roulette-option'>
                  <Form.Check type='checkbox' id={`default-checkbox-${oIndex}`} checked={!wheelOption.defaultHide} name={`header-checkbox-${oIndex}`} onChange={(e) => updateDefaultHide(e, wheelOption)} />
                  <span className='medium-font-size header-title'>{t(wheelOption.name)}</span>
                </div>
              </Accordion.Header>
              {wheelOption.subOptions?.length && (
                <Accordion.Body>
                  <div className='roulette-options'>
                    {wheelOption.subOptions?.map((subOption, sIndex) => (
                      <div className='roulette-option' key={`wheel-subOption-${sIndex}`}>
                        <Form.Check type='checkbox' id={`default-checkbox-${oIndex}-${sIndex}`} checked={!subOption.defaultHide} name={`body-checkbox-${oIndex}-${sIndex}`} onChange={(e) => updateDefaultHide(e, subOption, wheelOption)} />
                        <span className='medium-font-size'>{t(subOption.name)}</span>
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              )}
            </Accordion.Item>
          ))}
        </Accordion>
        <div className='buttons-container'>
          <Button className='custom-button' variant='secondary' onClick={resetWheelConfiguration}>{t('discard_changes')}</Button>
          <Button className='custom-button' variant='secondary' type='submit'>{t('save')}</Button>
        </div>
      </Form>
    </div>
  );
}

export default RouletteConfig;
