import { LocalStorageData, LocalStorageDataEvents } from '../enums/StorageData';
import { SummaryContent } from '../modules/summary/Summary';
import { getStorageValue, removeStorageValue, setStorageValue } from './StorageService';

export const setSummaryContent = (summary?: SummaryContent): void => {
  setStorageValue(LocalStorageData.summary, summary);
  window.dispatchEvent(new Event(LocalStorageDataEvents.summary));
};

export const getSummaryContent = (): SummaryContent => {
  return getStorageValue(LocalStorageData.summary, undefined);
};

export const resetSummaryContent = (): void => {
  removeStorageValue(LocalStorageData.summary);
  window.dispatchEvent(new Event(LocalStorageDataEvents.summary));
};