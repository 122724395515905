import './CustomModal.css';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

function CustomModal(params: { id: string; show: boolean; backdrop?: boolean; children: any; onHideModal: () => void; }) {
  const [show, setShow] = useState<boolean>(false);

  const hideModal = (): void => {
    setShow(false);
    params.onHideModal();
  };

  useEffect(() => {
    setShow(params.show);
  }, [params.show]);

  return (
    <div className='custom-modal-component'>
      <Modal
        id={params.id}
        show={show}
        centered
        onHide={hideModal}
        backdrop={params.backdrop ? true : 'static'}
      >
        <Modal.Body>{params.children}</Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomModal;
