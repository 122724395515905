import { Aspects } from '../enums/championProperties';
import { Regions } from '../enums/regions';
import { Roles } from '../enums/roles';
import { ChampionFilters } from '../enums/championFilters';

export interface Champion {
    name: string;
    [ChampionFilters.roles]: Roles[];
    [ChampionFilters.region]: Regions;
    [ChampionFilters.aspect]: Aspects[];
};

export const champions: Champion[] = [
    { name: 'Aatrox', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.withSword] },
    { name: 'Ahri', [ChampionFilters.roles]: [Roles.assassin, Roles.mage], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.furry, Aspects.humanoidWomen] },
    { name: 'Akali', [ChampionFilters.roles]: [Roles.assassin], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Akshan', [ChampionFilters.roles]: [Roles.marksman, Roles.assassin], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withFireWeapon] },
    { name: 'Alistar', [ChampionFilters.roles]: [Roles.support, Roles.tank], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.bald] },
    { name: 'Amumu', [ChampionFilters.roles]: [Roles.mage, Roles.tank], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [] },
    { name: 'Anivia', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [] },
    { name: 'Annie', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [] },
    { name: 'Aphelios', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [Aspects.cantTalk] },
    { name: 'Ashe', [ChampionFilters.roles]: [Roles.marksman, Roles.support], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Aurelion Sol', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [] },
    { name: 'Azir', [ChampionFilters.roles]: [Roles.mage, Roles.marksman], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [] },
    { name: 'Bard', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.chonky, Aspects.cantTalk] },
    { name: 'Bel\'Veth', [ChampionFilters.roles]: [Roles.fighter], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [] },
    { name: 'Blitzcrank', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [] },
    { name: 'Brand', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [] },
    { name: 'Braum', [ChampionFilters.roles]: [Roles.support, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.bald, Aspects.withMoustach] },
    { name: 'Briar', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Caitlyn', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [Aspects.withHat, Aspects.withFireWeapon, Aspects.humanoidWomen] },
    { name: 'Camille', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Cassiopeia', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Cho\'Gath', [ChampionFilters.roles]: [Roles.mage, Roles.tank], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [Aspects.chonky] },
    { name: 'Corki', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.bandleCity, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withFireWeapon, Aspects.withMount] },
    { name: 'Darius', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [] },
    { name: 'Diana', [ChampionFilters.roles]: [Roles.fighter, Roles.mage], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Dr. Mundo', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [] },
    { name: 'Draven', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.withMoustach] },
    { name: 'Ekko', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.withSword] },
    { name: 'Elise', [ChampionFilters.roles]: [Roles.fighter, Roles.mage], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Evelynn', [ChampionFilters.roles]: [Roles.assassin, Roles.mage], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Ezreal', [ChampionFilters.roles]: [Roles.mage, Roles.marksman], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Fiddlesticks', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.cantTalk] },
    { name: 'Fiora', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.withSword, Aspects.humanoidWomen] },
    { name: 'Fizz', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [] },
    { name: 'Galio', [ChampionFilters.roles]: [Roles.mage, Roles.tank], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [] },
    { name: 'Gangplank', [ChampionFilters.roles]: [Roles.fighter], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [Aspects.withHat, Aspects.bald, Aspects.withMoustach, Aspects.withSword, Aspects.withFireWeapon] },
    { name: 'Garen', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.withSword, Aspects.cantTalk] },
    { name: 'Gnar', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withHat, Aspects.furry, Aspects.cantTalk] },
    { name: 'Gragas', [ChampionFilters.roles]: [Roles.fighter, Roles.mage], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.bald, Aspects.withMoustach, Aspects.chonky] },
    { name: 'Graves', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withFireWeapon] },
    { name: 'Gwen', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Hecarim', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [] },
    { name: 'Heimerdinger', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withFireWeapon, Aspects.furry] },
    { name: 'Hwei', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [] },
    { name: 'Illaoi', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Irelia', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.withSword, Aspects.humanoidWomen] },
    { name: 'Ivern', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [] },
    { name: 'Janna', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Jarvan IV', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [] },
    { name: 'Jax', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [] },
    { name: 'Jayce', [ChampionFilters.roles]: [Roles.fighter, Roles.marksman], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [] },
    { name: 'Jhin', [ChampionFilters.roles]: [Roles.mage, Roles.marksman], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.bald, Aspects.withFireWeapon] },
    { name: 'Jinx', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.withFireWeapon, Aspects.humanoidWomen] },
    { name: 'K\'Sante', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [Aspects.withMoustach] },
    { name: 'Kai\'Sa', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Kalista', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Karma', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Karthus', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [Aspects.withHat] },
    { name: 'Kassadin', [ChampionFilters.roles]: [Roles.assassin, Roles.mage], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [] },
    { name: 'Katarina', [ChampionFilters.roles]: [Roles.assassin, Roles.mage], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Kayle', [ChampionFilters.roles]: [Roles.fighter, Roles.support], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.withSword, Aspects.humanoidWomen] },
    { name: 'Kayn', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [] },
    { name: 'Kennen', [ChampionFilters.roles]: [Roles.mage, Roles.marksman], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Kha\'Zix', [ChampionFilters.roles]: [Roles.assassin], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [Aspects.bald] },
    { name: 'Kindred', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Kled', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withFireWeapon, Aspects.furry, Aspects.withMount] },
    { name: 'Kog\'Maw', [ChampionFilters.roles]: [Roles.mage, Roles.marksman], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [] },
    { name: 'LeBlanc', [ChampionFilters.roles]: [Roles.assassin, Roles.mage], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Lee Sin', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.bald] },
    { name: 'Leona', [ChampionFilters.roles]: [Roles.support, Roles.tank], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [Aspects.withSword, Aspects.humanoidWomen] },
    { name: 'Lillia', [ChampionFilters.roles]: [Roles.fighter, Roles.mage], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.furry, Aspects.humanoidWomen] },
    { name: 'Lissandra', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withMount, Aspects.humanoidWomen] },
    { name: 'Lucian', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.withFireWeapon] },
    { name: 'Lulu', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.bandleCity, [ChampionFilters.aspect]: [Aspects.withHat, Aspects.furry] },
    { name: 'Lux', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Malphite', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.ixtal, [ChampionFilters.aspect]: [] },
    { name: 'Malzahar', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [] },
    { name: 'Maokai', [ChampionFilters.roles]: [Roles.mage, Roles.tank], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [] },
    { name: 'Master Yi', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withSword] },
    { name: 'Milio', [ChampionFilters.roles]: [Roles.support], [ChampionFilters.region]: Regions.ixtal, [ChampionFilters.aspect]: [] },
    { name: 'Miss Fortune', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [Aspects.withHat, Aspects.withFireWeapon, Aspects.humanoidWomen] },
    { name: 'Mordekaiser', [ChampionFilters.roles]: [Roles.fighter], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [] },
    { name: 'Morgana', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Naafiri', [ChampionFilters.roles]: [Roles.assassin], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Nami', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Nasus', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Nautilus', [ChampionFilters.roles]: [Roles.support, Roles.tank], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [] },
    { name: 'Neeko', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.ixtal, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Nidalee', [ChampionFilters.roles]: [Roles.assassin, Roles.mage], [ChampionFilters.region]: Regions.ixtal, [ChampionFilters.aspect]: [Aspects.furry, Aspects.humanoidWomen] },
    { name: 'Nilah', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Nocturne', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [] },
    { name: 'Nunu & Willump', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withMount] },
    { name: 'Olaf', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withMoustach] },
    { name: 'Orianna', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Ornn', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withMoustach] },
    { name: 'Pantheon', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [Aspects.withMoustach] },
    { name: 'Poppy', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Pyke', [ChampionFilters.roles]: [Roles.assassin, Roles.support], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [Aspects.bald] },
    { name: 'Qiyana', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ixtal, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Quinn', [ChampionFilters.roles]: [Roles.assassin, Roles.marksman], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.withMount, Aspects.humanoidWomen] },
    { name: 'Rakan', [ChampionFilters.roles]: [Roles.support], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Rammus', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [Aspects.chonky] },
    { name: 'Rek\'Sai', [ChampionFilters.roles]: [Roles.fighter], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [Aspects.cantTalk] },
    { name: 'Rell', [ChampionFilters.roles]: [Roles.support, Roles.tank], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.withMount, Aspects.humanoidWomen] },
    { name: 'Renata Glasc', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.withFireWeapon, Aspects.humanoidWomen] },
    { name: 'Renekton', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [] },
    { name: 'Rengar', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ixtal, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.furry] },
    { name: 'Riven', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.withSword, Aspects.humanoidWomen] },
    { name: 'Rumble', [ChampionFilters.roles]: [Roles.fighter, Roles.mage], [ChampionFilters.region]: Regions.bandleCity, [ChampionFilters.aspect]: [Aspects.furry, Aspects.withMount] },
    { name: 'Ryze', [ChampionFilters.roles]: [Roles.fighter, Roles.mage], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.bald] },
    { name: 'Samira', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.withSword, Aspects.withFireWeapon, Aspects.humanoidWomen] },
    { name: 'Sejuani', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withMount, Aspects.humanoidWomen] },
    { name: 'Senna', [ChampionFilters.roles]: [Roles.marksman, Roles.support], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Seraphine', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [Aspects.withMount, Aspects.humanoidWomen] },
    { name: 'Sett', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Shaco', [ChampionFilters.roles]: [Roles.assassin], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [] },
    { name: 'Shen', [ChampionFilters.roles]: [Roles.tank], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.withSword] },
    { name: 'Shyvana', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Singed', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.bald] },
    { name: 'Sion', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [Aspects.bald] },
    { name: 'Sivir', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Skarner', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [] },
    { name: 'Sona', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.humanoidWomen, Aspects.cantTalk] },
    { name: 'Soraka', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [Aspects.furry, Aspects.humanoidWomen] },
    { name: 'Swain', [ChampionFilters.roles]: [Roles.fighter, Roles.mage], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [] },
    { name: 'Sylas', [ChampionFilters.roles]: [Roles.assassin, Roles.mage], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.withMoustach] },
    { name: 'Syndra', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Tahm Kench', [ChampionFilters.roles]: [Roles.support, Roles.tank], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [Aspects.withHat, Aspects.withMoustach, Aspects.chonky] },
    { name: 'Taliyah', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [Aspects.withMount, Aspects.humanoidWomen] },
    { name: 'Talon', [ChampionFilters.roles]: [Roles.assassin], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [] },
    { name: 'Taric', [ChampionFilters.roles]: [Roles.fighter, Roles.support], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [] },
    { name: 'Teemo', [ChampionFilters.roles]: [Roles.assassin, Roles.marksman], [ChampionFilters.region]: Regions.bandleCity, [ChampionFilters.aspect]: [Aspects.withHat, Aspects.furry] },
    { name: 'Thresh', [ChampionFilters.roles]: [Roles.fighter, Roles.support], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [] },
    { name: 'Tristana', [ChampionFilters.roles]: [Roles.assassin, Roles.marksman], [ChampionFilters.region]: Regions.bandleCity, [ChampionFilters.aspect]: [Aspects.withFireWeapon] },
    { name: 'Trundle', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [] },
    { name: 'Tryndamere', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withSword] },
    { name: 'Twisted Fate', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.bilgewater, [ChampionFilters.aspect]: [Aspects.withHat] },
    { name: 'Twitch', [ChampionFilters.roles]: [Roles.assassin, Roles.marksman], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [] },
    { name: 'Udyr', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.chonky] },
    { name: 'Urgot', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.bald, Aspects.withFireWeapon, Aspects.chonky] },
    { name: 'Varus', [ChampionFilters.roles]: [Roles.mage, Roles.marksman], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [] },
    { name: 'Vayne', [ChampionFilters.roles]: [Roles.assassin, Roles.marksman], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Veigar', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.bandleCity, [ChampionFilters.aspect]: [Aspects.withHat] },
    { name: 'Vel\'Koz', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.void, [ChampionFilters.aspect]: [] },
    { name: 'Vex', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Vi', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.piltover, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Viego', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [Aspects.withSword] },
    { name: 'Viktor', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [] },
    { name: 'Vladimir', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.noxus, [ChampionFilters.aspect]: [] },
    { name: 'Volibear', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.freljord, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Warwick', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Wukong', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Xayah', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.furry, Aspects.humanoidWomen] },
    { name: 'Xerath', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.shurima, [ChampionFilters.aspect]: [] },
    { name: 'Xin Zhao', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.demacia, [ChampionFilters.aspect]: [] },
    { name: 'Yasuo', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.withSword] },
    { name: 'Yone', [ChampionFilters.roles]: [Roles.assassin, Roles.fighter], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [Aspects.withSword] },
    { name: 'Yorick', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.shadowIsles, [ChampionFilters.aspect]: [] },
    { name: 'Yuumi', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.bandleCity, [ChampionFilters.aspect]: [Aspects.withMoustach, Aspects.furry, Aspects.withMount] },
    { name: 'Zac', [ChampionFilters.roles]: [Roles.fighter, Roles.tank], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.chonky] },
    { name: 'Zed', [ChampionFilters.roles]: [Roles.assassin], [ChampionFilters.region]: Regions.ionia, [ChampionFilters.aspect]: [] },
    { name: 'Zeri', [ChampionFilters.roles]: [Roles.marksman], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Ziggs', [ChampionFilters.roles]: [Roles.mage], [ChampionFilters.region]: Regions.zaun, [ChampionFilters.aspect]: [Aspects.furry] },
    { name: 'Zilean', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.runaterra, [ChampionFilters.aspect]: [Aspects.withMoustach] },
    { name: 'Zoe', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.targon, [ChampionFilters.aspect]: [Aspects.humanoidWomen] },
    { name: 'Zyra', [ChampionFilters.roles]: [Roles.mage, Roles.support], [ChampionFilters.region]: Regions.ixtal, [ChampionFilters.aspect]: [Aspects.humanoidWomen] }
];