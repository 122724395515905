import { champions } from '../databases/champions';
import { WheelOption, WheelSubOption, wheelOptions } from '../databases/wheelOptions';
import { ChampionFilters } from '../enums/championFilters';
import { LocalStorageDataEvents, LocalStorageData, SessionStorageData } from '../enums/StorageData';
import { WheelFunctions } from '../enums/wheelFunctions';
import { getStorageValue, removeStorageValue, setStorageValue } from './StorageService';

export const setSessionWheelOptions = (_wheelOptions: WheelOption[]): void => {
  setStorageValue(SessionStorageData.wheelOptions, _wheelOptions);
};

export const getSessionWheelOptions = (): WheelOption[] => {
  const sessionWheelOptions = (getStorageValue(SessionStorageData.wheelOptions, []) as WheelOption[]);
  const clonnedWheelOptions = getClonnedWheelOptions();

  return getMergedWheelOptions(sessionWheelOptions, clonnedWheelOptions);
};

export const setLocalWheelOptions = (_wheelOptions?: WheelOption[]): void => {
  setStorageValue(LocalStorageData.wheelOptions, _wheelOptions);
  window.dispatchEvent(new Event(LocalStorageDataEvents.wheelOptions));
};

export const getLocalWheelOptions = (_wheelOptions?: WheelOption[]): WheelOption[] => {
  return getStorageValue(LocalStorageData.wheelOptions, getSessionWheelOptions()) as WheelOption[];
};

export const getFilteredWheelOptions = (): WheelOption[] => {
  const localWheelOptions = getLocalWheelOptions();
  const sessionWheelOptions = getSessionWheelOptions();

  return getMergedWheelOptions(localWheelOptions, sessionWheelOptions).filter(_wheelOption => !_wheelOption.hide && !_wheelOption.defaultHide);
};

export const getFilteredSecondaryWheelOptions = (wheelOptionTitle: string): WheelSubOption[] => {
  const wheelOption = (getStorageValue(LocalStorageData.wheelOptions, getSessionWheelOptions()) as WheelOption[])
    .find(wheelOption => wheelOption.name === wheelOptionTitle);

  return wheelOption?.subOptions?.filter(subOption => !subOption.hide && !subOption.defaultHide) as WheelSubOption[];
};

export const getSecondaryWheelOptionOptions = (wheelOption: WheelOption, wheelSubOption: WheelSubOption): string[] | undefined => {
  if (!!wheelOption?.subOptionsType) {
    return champions
      .filter(champion => {
        const championValues = champion[wheelOption.subOptionsType as ChampionFilters] as string | string[];
        return (wheelSubOption.value)
          ? (typeof championValues === 'object' && championValues.includes(wheelSubOption.value)) || championValues === wheelSubOption.value
          : false;
      })
      .map(champion => champion.name)
  }

  return;
};

export const hideWheelOption = (wheelOptionTitle: string, wheelOptionSubtitle?: string): void => {
  const _wheelOptions = getLocalWheelOptions();
  const selectedWheelOption = _wheelOptions.find(_wheelOption => _wheelOption.name === wheelOptionTitle);

  if (selectedWheelOption) {
    if (wheelOptionSubtitle) {
      const selectedWheelSubOption = selectedWheelOption.subOptions?.find(subOption => subOption.name === wheelOptionSubtitle);
      if (selectedWheelSubOption) {
        selectedWheelSubOption.hide = true;
      }
      // Check if all subOptions are hidden to hide wheel option
      if (selectedWheelOption.subOptions?.every(subOption => subOption.hide || subOption.defaultHide)) {
        selectedWheelOption.hide = true;
      }
    } else {
      selectedWheelOption.hide = true;
    }

    setLocalWheelOptions(_wheelOptions);
  }
};

export const resetWheelOptions = (): void => {
  removeStorageValue(LocalStorageData.wheelOptions);
  window.dispatchEvent(new Event(LocalStorageDataEvents.wheelOptions));
};

export const canResetWheelOptions = (): boolean => {
  const currentWheelOptions = getLocalWheelOptions();

  return currentWheelOptions.some(wheelOption => wheelOption.hide || wheelOption.subOptions?.some(subOption => subOption.hide)) || false;
};

export const hasMainChampionOptions = (): boolean => {
  const currentWheelOptions = getFilteredWheelOptions();

  return currentWheelOptions.some(wheelOption => wheelOption.action && [WheelFunctions.partnersMain, WheelFunctions.enemyMains].includes(wheelOption.action)) || false;
};

const getClonnedWheelOptions = (): WheelOption[] => {
  const _wheelOptions = [...wheelOptions].map(_wheelOption => ({
    ..._wheelOption,
    subOptions: _wheelOption.subOptions?.map(subOption => ({ ...subOption }))
  }));

  return _wheelOptions;
};

const getMergedWheelOptions = (arrayOne: WheelOption[], arrayTwo: WheelOption[]): WheelOption[] => {
  return [...arrayOne, ...arrayTwo].reduce((acc, wheelOption) => {
    const existingItem = acc.find(_wheelOption => _wheelOption.name === wheelOption.name);
    if (existingItem) {
      existingItem.defaultHide = existingItem.defaultHide || wheelOption.defaultHide;
      return acc;
    }

    return [...acc, wheelOption];
  }, [] as WheelOption[]);
};