import './Layout.css';
import Game from '../game/Game';
import TeamRandomizer from '../team-randomizer/TeamRandomizer';
import Summary from '../summary/Summary';
import InfoBlock from '../info-block/InfoBlock';
import ConfigBlock from '../config-block/ConfigBlock';

function Layout() {
  return (
    <div className='layout-component'>
      <div className='header big-font-size'>Lol Roulette</div>
      <div className='top-right-block'>
        <ConfigBlock />
        <InfoBlock />
      </div>
      <div className='content'>
        <TeamRandomizer />
        <Game />
        <Summary />
      </div>
    </div>
  );
}

export default Layout;
