import './RandomChampion.css';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Player, getTeams } from '../../../services/TeamsService';
import { champions } from '../../../databases/champions';
import { useTranslation } from 'react-i18next';

interface PlayerWithRandomChampion extends Player {
  randomChampion?: string;
}

function RandomChampion() {
  const { t } = useTranslation('common');

  const parsedTeams: PlayerWithRandomChampion[][] = getTeams().map(players => players.map(player => ({ ...player, randomChampion: undefined })));
  const [teams, setTeams] = useState<PlayerWithRandomChampion[][]>(parsedTeams);
  const [missingRandomChampions, setMissingRandomChampions] = useState<boolean>(true);

  const getRandomChampion = (): void => {
    const teamWithoutRandomChampions = teams.find(team => team.every(player => !player.randomChampion)) as PlayerWithRandomChampion[];
    const indexOfTeam = teams.indexOf(teamWithoutRandomChampions);
    const _teams = [...teams];
    _teams[indexOfTeam] = _teams[indexOfTeam].map(player => ({
      ...player,
      randomChampion: champions[Math.floor(Math.random() * champions.length)].name
    }))

    setTeams(_teams);
    if (indexOfTeam === teams.length - 1) {
      setMissingRandomChampions(false);
    }
  };

  return (
    <div className='random-champion-component'>
      {teams.map((team, tIndex) => (
        <div className='team-container' key={`team-${tIndex}`}>
          <span className='team-title medium-font-size'>{t('team')} {tIndex + 1}</span>
          <div className='champion-selectors'>
            {team.map((player, pIndex) => (
              <div key={`player-${pIndex}`} className='small-font-size'>{player.name} {t('will_play_with')} {player.randomChampion ? '' : '...'}<span className={`random-champion small-font-size${player.randomChampion ? ' show' : ''}`}>{player.randomChampion}</span></div>
            ))}
          </div>
        </div>
      ))}

      {missingRandomChampions && (
      <Button className='custom-button' variant='secondary' onClick={getRandomChampion}>{t('show_champion')}</Button>
      )}
    </div>
  );
}

export default RandomChampion;
