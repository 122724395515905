import './LuckyWheel.css';
import { useEffect, useState } from 'react';
import { WheelOption, WheelSubOption } from '../../databases/wheelOptions';
import { Wheel } from 'react-custom-roulette';
import { useTranslation } from 'react-i18next';

function LuckyWheel(params: { options: (WheelOption | WheelSubOption)[]; onSelectOption: (param: WheelOption | WheelSubOption) => void; checkEnabled?: () => boolean; startSpin?: () => void; }) {
  const { t } = useTranslation('common');

  const [parsedOptions, setParsedOptions] = useState<{ option: string }[]>([]);
  const [mustSpin, setMustSpin] = useState<boolean>(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);

  const handleSpinClick = (): void => {
    const enabledWheel = params.checkEnabled?.() ?? true;
    if (enabledWheel) {
      const index = Math.floor(Math.random() * params.options.length);
      setSelectedOptionIndex(index);
      params.startSpin?.();
      setMustSpin(true);
    }
  };

  const handleSpinFinish = (): void => {
    setMustSpin(false);
    params.onSelectOption(params.options[selectedOptionIndex]);
  };

  useEffect(() => {
    setParsedOptions(params.options?.map(option => ({ option: t(option.name) })) || []);
  }, [params.options, t]);

  return (
    <div className='lucky-wheel-container'>
      {parsedOptions.length && (
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={selectedOptionIndex}
          data={parsedOptions}

          onStopSpinning={handleSpinFinish}

          // Colors
          backgroundColors={['#0A323C', '#463714']}
          textColors={['#ffffff']}

          outerBorderColor='#C89B3C'
          outerBorderWidth={5}

          radiusLineColor='#C89B3C'
          radiusLineWidth={2}

          // Fonts
          fontSize={14}
          fontWeight='normal'

        // spinDuration={0.01}
        />
      )}

      <div className='roulette-center clickable-item' onClick={handleSpinClick}></div>
    </div>
  );
}

export default LuckyWheel;
